import { asyncGetDocTreeData, asyncSetAllCountry, asyncSetAreaTree, asyncSetDomainInfo, asyncSetMsgInfo, asyncSetProductData, asyncSetUserInfo } from '@store/actions';
import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, HashRouter, Router} from 'react-router-dom';
import RouterFn from './router'
import store, { StateType } from './store'
import { customerOnline, getToken, isMobile, setWebsite } from './utils';

const InitApi = ({children}: any) => {
    const dispatch = useDispatch();
    const { isnull, ueSaasIcon, ueSaasName } = useSelector((state: StateType) => state.domainInfo) as any;
   
    const initApi = async () => {
        const token = getToken()
        console.log('是否手机端', isMobile())
        await dispatch(asyncSetDomainInfo()) // 获取个性化配置信息，首先查询，避免个别数据为空
        // 获取个性化信息是指从接口获取网页的logo  企业名称  页面标题icon等，获取之后存在reducers，目的是为了灵活的配置，sass考虑
        if (token) {
            await dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
            await dispatch(asyncSetMsgInfo()) // 获取消息
        }
        await dispatch(asyncSetProductData()) // 获取国内短信设置
        // await dispatch(asyncSetAreaTree()) // 获取省市区数据  // 接口数据过多，为了节流 暂时不获取，用到的时候再获取
        await dispatch(asyncSetAllCountry()) // 获取所有国家
        dispatch(asyncGetDocTreeData()) // 获取文档树
    }
    useEffect(() => {
        initApi()
    }, [])

    // 获取当前企业是否是本企业，是的话添加在线客服的脚本
    useEffect(()=>{
        isnull && customerOnline()
        setWebsite({
            ico: ueSaasIcon,
            title: ueSaasName
        })
    }, [isnull])
    return children
}

const App = () => {
    return (
        <div className="App">
            <Provider store={store}>
                <InitApi>
                    <BrowserRouter>
                        <RouterFn />
                    </BrowserRouter>
                </InitApi>
            </Provider>
        </div>
    )
}

export default App
