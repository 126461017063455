import ProTable, { ActionType } from "@ant-design/pro-table"
import { ProFormDependency } from '@ant-design/pro-form';
import { homeSearch, homeTableAddUpdate, homeTableDel } from "@api/home"
import { acceptExcel, filterObj, getToken, productApi, setHref } from "@utils/util"
import { Button, message, Modal, Popover, Tabs } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { columnsFn, sendType } from "./data"
import ProForm, {
    ModalForm,
    ProFormText,
    ProFormSelect,
    ProFormCascader,
    ProFormRadio,
    ProFormTextArea,
    ProFormDateTimePicker,
    ProFormUploadButton
} from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import {
    BASEURL,
    smsSendPageList,
    smsSignPageList,
    smsSendMessageByFormData,
    smsSendDownLoadFailPhone,
    smsTempGetTempTypes,
    smsTempPageList,
    smsSendResend,
    smsSendCancel,
    smsTempVarOptions
} from "@api/index"
import { SendMsgPreview } from "@components/Common"
import FormItem from "antd/es/form/FormItem";
import { MsgSendType } from "@components/Common/SendMsgCpn";
import { handleDvmCommonListResult, handleDvmCommonPageListResult } from "@utils/request";
import UploadButton from "@components/Common/Upload";
import { asyncSetUserInfo, setTaskObj } from "@store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CERT_COMPANY_FAIL, CERT_PERSON_FAIL, calcCertStatus, smsTypeOptions } from "@utils/enum";
import { fileExportUrl } from "@api/baseUrl";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { StateType } from "@store/index";

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

// 接口请求的 api 层
const getApi = productApi(smsSendPageList)  // 查询的接口封装
const addUpdateApi = productApi(homeTableAddUpdate) // 列表数据的新增/编辑 接口
const delApi = productApi(homeTableDel) // 表单数据的删除 接口
const sendApi = productApi(smsSendMessageByFormData) // 短信在线发送
const getListApi = handleDvmCommonPageListResult(getApi)
const downLoadFailPhoneApi = productApi(smsSendDownLoadFailPhone) // 下载失败号码
const reSendApi = productApi(smsSendResend) // 错误短信重新发送
const cancelApi = productApi(smsSendCancel) // 取消发送任务

// 添加群发任务
interface AddBatchSendTaskProps extends ModalFormProps {
    params?: any;
    onSuccess?: () => void;
}
const AddBatchSendTask = ({ params, onSuccess, ...rest }: AddBatchSendTaskProps) => {
    const formRef = useRef<ProFormInstance>();
    const [signContent, setSignContent] = useState('' as string)
    const [options, setOptions] = useState(smsTypeOptions)
    const [numberList, setNumberList] = useState([])
    const [templateContent, setTemplateContent] = useState('' as string)
    const onFinish = async (values: any) => {
        console.log('表单要提交了，提交参数为:', values, params)
        const { success } = await sendApi(filterObj({
            ...values,
            ...params,
            file: values.file ? values.file[0]?.originFileObj || '' : null,
            templateCode: values.templateCode ? values.templateCode[1] : '',
            phone: values.typeCode === 3 ? numberList : null
        }), true, () => {
            clearData()
            onSuccess ? onSuccess() : null
        })
        return success
    }

    const validTime = (rule: any, value: Date, callback: any) => {
        const userTime = new Date(value).valueOf()
        const sysTime = new Date().valueOf()
        if (userTime < sysTime) {
            callback('选择时间要大于当前5分钟后的时间')
        } else if (userTime < sysTime + 300000 && userTime > sysTime) {
            callback('选择时间要大于当前5分钟后的时间')
        } else {
            callback()
        }
    }
    const Explain_1 = (
        <div>
            ①选择已经审核通过的模板。<br />
            ②如果短信模板有变量，仅支持上传文件。<br />
            ③如果短信模板无变量，支持上传文件、通讯录或者手动输入号码 。<br />
        </div>
    )
    const Explain_2 = (
        <div>
            ①可在下方直接输入“模板内容”。<br />
            ②上传手机号码的方式：excel上传/通讯录/手动输入。<br />
            ③不支持有变量的短信内容 。<br />
            ④任务提交以后进入待审核,审核通过后将立即执行发送任务（1小时内审核） 。<br />
        </div>
    )
    const loadData = async (selectedOptions: any) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        // load options lazily
        // async () => {
        const { result } = await smsTempPageList({ pageNum: 1, pageSize: 999, utlSmsArea: 1, utlSmsType: targetOption.value, utlVerifyState: 2 }) as any
        const res = handleDvmCommonListResult(result)
        console.log(res)
        res.length === 0 && message.info('当前类型下还没有模板')
        targetOption.loading = false;
        targetOption.children = res?.map(({ utlCode: value, utlName: label, utlContent }: any) => ({ label, value, utlContent }))
        setOptions([...options])
    };

    const getOptions = async () => {
        const { result } = await smsTempVarOptions({ pageNum: 1, pageSize: 999 }) as any
        const dataSource = handleDvmCommonListResult(result, 'get_template_type')
        console.log(22223, dataSource?.map(({ utrParamType: value, utrName: label }: any) => ({ label, value, isLeaf: false })))
        setOptions(dataSource?.map(({ utlSmsType: value, utrName: label }: any) => ({ label, value, isLeaf: false })))
    }
    useEffect(()=> {
        getOptions()
    }, [])
    const clearData = () => {
        formRef.current?.resetFields()
        setSignContent('')
        setNumberList([])
        setTemplateContent('')
    }
    return (<ModalForm<any>
        layout="horizontal"
        width={1000}
        formRef={formRef}
        modalProps={{
            onCancel: clearData
        }}
        {...layout}
        onFinish={onFinish}
        {...rest}>
        <SendMsgPreview msgContent={`${signContent ? '【' + signContent + '】' : ''}${templateContent}`} />
        <div style={{ width: '60%' }}>

            <ProFormDependency name={['sendTypeCode', 'immediately']}>
                {({ sendTypeCode, immediately }: any) => {
                    return (
                        <>
                            <ProFormSelect
                                width="md"
                                name="sendTypeCode"
                                label="选择群发方式"
                                extra={<Popover content={sendTypeCode === 2 ? Explain_2 : Explain_1} title="群发方式说明">
                                    <div className="text-blue-500 mt-2 relative float-right pr-24">群发方式说明</div>
                                </Popover>
                                }
                                fieldProps={{
                                    allowClear: false,
                                    placeholder: '请选择群发方式',
                                    defaultValue: 1,
                                    onChange: (e) => {
                                        clearData()
                                        formRef.current?.setFieldValue('sendTypeCode', e)
                                    }
                                }}
                                options={sendType}
                                rules={[{ required: false, message: '请选择群发方式' }]}
                            />

                            <ProFormText
                                width="md"
                                name="taskName"
                                label="任务名称"
                                rules={[{ required: true, message: '请输入任务名称' }]}
                                placeholder={sendTypeCode === 2 ? '请输入任务名称，将自动保存为模板名称' : '请输入任务名称'}
                            />
                            {/* 短信签名 */}
                            <ProFormSelect
                                width="md"
                                name="signCode"
                                label="短信签名"
                                request={
                                    async () => {
                                        const { result } = await smsSignPageList({ pageNum: 1, pageSize: 999, utlVerifyState: 2 }) as any
                                        const res = handleDvmCommonListResult(result)
                                        return res?.map(({ usCode: value, usName: label }: any) => ({ label, value }))
                                    }
                                }
                                fieldProps={{
                                    placeholder: '请选择短信签名',
                                    showSearch: true,
                                    onChange: (value: any, option: any) => setSignContent(option.label)

                                }}
                                rules={[{ required: false, message: '请选择短信签名' }]}
                            />
                            {/* 短信模板 */}
                            {sendTypeCode === 2 ? null :
                                <ProFormCascader
                                    width="md"
                                    name="templateCode"
                                    label="短信模版"
                                    fieldProps={{
                                        placeholder: '请选择短信模版',
                                        loadData: loadData,
                                        options: options,
                                        // showSearch: true,
                                        onChange: (value: any, option: any) => setTemplateContent(value ? option[1].utlContent : '')
                                    }}

                                    rules={[{ required: false, message: '请选择短信模版' }]}
                                />
                            }
                            {/* 套餐分类 */}
                            {sendTypeCode === 2 ?
                                <ProFormSelect
                                    width="md"
                                    name="classSecret"
                                    label="套餐分类"
                                    showSearch
                                    request={
                                        async () => {
                                            const { result } = await smsTempVarOptions({ pageNum: 1, pageSize: 999 }) as any
                                            const dataSource = handleDvmCommonListResult(result, 'get_template_type')
                                            return dataSource?.map(({ utlSmsType: value, utrName: label }: any) => ({ label, value }))
                                        }
                                    }
                                    fieldProps={{
                                        placeholder: '请选择套餐分类',
                                        showSearch: true,
                                        filterOption: false
                                    }}
                                    options={[]}
                                    rules={[{ required: false, message: '请选择套餐分类' }]}
                                /> : null
                            }
                            {/* 模板内容 */}
                            {sendTypeCode === 2 ?
                                <ProFormTextArea
                                    width="md"
                                    name="templateContent"
                                    label="模板内容"
                                    extra={<div className="mt-2">
                                        • 如发送营销短信，模板末尾需加上“退订回T”，占模版内容4个字数。<br />
                                        • 不能发送贷款/借款/中奖/抽奖类短信,不支持金融理财&房产通知类短信。
                                    </div>}
                                    fieldProps={{
                                        placeholder: '请输入模板内容，不含变量',
                                        onChange: (e: any) => setTemplateContent(e.target.value)
                                    }}
                                    rules={[{ required: false, message: '请输入模板内容，不含变量' }]}
                                /> : null
                            }
                            {/* 上传号码 */}
                            {!/\$\{(.+?)\}/g.test(templateContent) ?
                                <FormItem
                                    name="typeCode"
                                    initialValue={1}
                                    rules={[{ required: true, message: '' }]}
                                    label="上传号码">
                                    <MsgSendType setNumberList={setNumberList} formRef={formRef} />
                                </FormItem> :
                                <ProFormUploadButton
                                    width="md"
                                    name="file"
                                    label="上传号码"
                                    fieldProps={{
                                        name: 'file',
                                        maxCount: 1,
                                        beforeUpload: (file: any) => {
                                            console.log(file)
                                            return false
                                        }
                                    }}
                                    rules={[{ required: true, message: '请上传资料' }]}
                                    extra={<div>
                                        单次最多上传100000个号码，单次任务最大支持8个变量。<br />
                                        点击下载：<a href="https://cloud-parent.oss-cn-hangzhou.aliyuncs.com/fileServer/%E7%BE%A4%E5%8F%91%E7%9F%AD%E4%BF%A1%E6%A8%A1%E6%9D%BF%281%29.xlsx">群发模板</a> 导入手机号和变量。
                                    </div>}
                                />
                                //     <UploadButton 
                                //     width="md"
                                //     name="multipartFile"
                                //     label="导入号码" 
                                //     rules={[{required: true, message: '请上传资料'}]} 
                                //     fieldProps={{
                                //       accept: acceptExcel,
                                //       listType: 'text',
                                //       maxCount: 1
                                //     }}
                                //     extra={<div>单次最多上传100000个号码；将号码放至第一页第一列，从一行开始，<a>模板下载</a> 导入文件的同时会过滤格式错误和重复的手机号码</div>}
                                //   />
                            }

                            {/*  */}
                            <ProFormRadio.Group
                                name="immediately"
                                label="发送时间"
                                initialValue="1"
                                options={[
                                    {
                                        label: '立刻',
                                        value: '1',
                                    },
                                    {
                                        label: '定时发送',
                                        value: '2',
                                    },
                                ]}
                            />
                            {
                                immediately === '2' ?
                                    <ProFormDateTimePicker
                                        name="taskTime"
                                        label="日期时间"
                                        extra={<div className="text-gray-300 mt-2">请选择大于当前时间5分钟后的时间<br /> 定时发送时间：每天上午8:00到晚上22:00点</div>}
                                        rules={[{ required: true, message: '请选择时间' }, { validator: validTime }]}
                                    />
                                    : null
                            }
                        </>

                    );
                }}
            </ProFormDependency>
        </div>
    </ModalForm>)
}

// 在线发送
export default () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [modalProps, setModalProps] = useState({ visible: false, title: '新增', params: {} })
    const { baseInfo, certInfo } = useSelector((store: StateType) => store?.userInfo) as any
    const { userNature: type, verifyState: status } = (baseInfo || {}) as any
    const STATUS = calcCertStatus(type, status)
    const dispatch = useDispatch()
    const history = useHistory()
    const ref = useRef<ActionType>(null); // ProTable 组件的 ref
    // operate 函数为增删改查需要处理的业务
    const operate = async (type: any, record: any) => {
        const { tiId, usName, utlName, utlContent, tiAmount, susCount, failCount, otherCount } = record || {}
        if (type === '新增') {
            // 需求方说 认证失败{个人/企业} 不允许新增
            if ([CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS)) return message.warning('实名认证失败，不允许操作')
            setModalProps({
                ...modalProps,
                title: '新增',
                params: {},
                visible: true,
            })
            console.log('需要处理的新增业务')
        } else if (type === '任务详情') {
            Modal.info({
                title: '详情信息',
                content: (
                    <div>
                        <div>签名名称：{usName}</div>
                        <div>模板名称：{utlName}</div>
                        <div>短信内容：{utlContent}</div>
                        <div>发送总数：{tiAmount}</div>
                        <div>发送成功：{susCount}</div>
                        <div>发送失败：{failCount}</div>
                        <div>发送中：{otherCount}</div>
                    </div>
                ),
                onOk() { },
            })
        } else if (type === '删除') {
            const ids = record ? [tiId] : selectedRowKeys
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                centered: true,
                onOk: async () => {
                    await delApi({ ids }, true, () => {
                        console.log('数据删除成功, 可以执行回调了')
                        reFresh()
                    })
                },
            });
        } else if (type === '下载失败号码') {
            await downLoadFailPhoneApi({ tiId }, false, (data: any) => {
                // const { file: filename } = data || {}
                // const url = fileExportUrl(filename)
                // filename && window.open(url)
                const { exportType, serialNumber } = data || {} //exportType: 1 同步，2 异步
                if (exportType === 1) {
                    dispatch(setTaskObj('ADD_TASK', {[serialNumber]: {}}))
                } else if (exportType === 2) {
                    Modal.confirm({
                        title: '温馨提示',
                        content: <div>后端已接收该导出任务，具体请前往<a onClick={() => setHref('/account/taskList')}>导出记录</a>查看</div>,
                        okText: '确认',
                        centered: true,
                        cancelText: '取消',
                    });
                }
            })
        } else if (type === '失败号码重新发送') {
            Modal.confirm({
                title: '确认要重新发送失败的号码吗?',
                content: '',
                centered: true,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    await reSendApi({ taskId: tiId }, true, () => {
                        reFresh()
                    })
                },
            });
            reSendApi
        } else if (type === '发送明细') {
            const { tiId, tiExecTime: sendTime, createTime } = record || {}
            const queryParam = { tiId, taskOrApiDetails: 1, sendTime, createTime }
            history.push(`/product/sms/statistics/sendDetail?${stringify(queryParam)}`)
        } else if (type === '取消发送') {
            Modal.confirm({
                title: '确认要取消发送定时任务吗?',
                content: '',
                okText: '确认',
                centered: true,
                cancelText: '取消',
                onOk: async () => {
                    await cancelApi({ taskId:tiId }, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({ ...params }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    // const getListApi = handleDvmCommonPageListResult(getApi)
    const columns = columnsFn(operate) // 表格列配置

    // 新增/修改提交表单后的回调操作
    const onSuccess = () => {
        reFresh()
        dispatch(asyncSetUserInfo()) as any // 获取账号权限信息
    }
    // 刷新页面
    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const calcDelDisabled = () => selectedRowKeys.length
    return (<ProTable<any>
        style={{ flex: 1, overflow: 'scroll' }}
        scroll={{ x: 2000 }}
        columns={columns}
        params={{ tiType: 1 }} // 查询所需要的额外参数
        request={getListApi}
        options={{ reload: false, density: false, setting: false }}
        toolbar={{
            actions: [
                [CERT_PERSON_FAIL, CERT_COMPANY_FAIL].includes(STATUS) ? <Button type='primary' onClick={() => message.warning('实名认证失败，不允许操作')}>添加群发任务</Button> :
                <AddBatchSendTask onSuccess={onSuccess} key="btn0" title="添加群发任务" trigger={<Button type='primary'>添加群发任务</Button>} />,
                // <Button key="btn1" type='primary' disabled={!calcDelDisabled()} onClick={() => operate('删除', null)}>删除</Button>,
            ],
        }}
        rowKey="tiId"
        pagination={{defaultPageSize: 10, showSizeChanger: true, showQuickJumper: true}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => [...dom.reverse()]
        }}
    // rowSelection={{
    //     preserveSelectedRowKeys: true,
    //     onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
    // }}
    />)
}
